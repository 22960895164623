<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>验证信息</span>
      </div>
    </div>
    <div class="ers">
      <van-field v-model="formdata.tel" type="tel" label="手机号" />
      <van-field v-model="formdata.yzCode" label="验证码" />
      <span class="revfd" @click="fsyzm">{{ authCode }}</span>
      <p></p>
    </div>
  </div>
</template>

<script>
import { getSmsCode } from "@/request/api";
export default {
  data() {
    return {
      formdata: {
        tel: "",
        yzCode: "",
      },
      authCode: "发送验证码",
      flag: true,
    };
  },
  methods: {
    //发送验证码
    fsyzm() {
      if (this.flag) {
        this.flag = false;
        let that = this;
        console.log(this.formdata.tel);
        if (
          this.formdata.tel != "" &&
          /^1[345678]\d{9}$/.test(this.formdata.tel)
        ) {
          this.authCode = "60";
          var clear = setInterval(function () {
            if (that.authCode == 0) {
              that.authCode = "发送验证码";
              clearInterval(clear);
              this.flag = true;
            } else {
              that.authCode--;
            }
          }, 1000);
          getSmsCode({
            mobile: this.formdata.tel,
          }).then((res) => {
            console.log(res);
            // if(res.data)
          });
        } else {
          this.$notify({ type: "warning", message: "请输入手机号" });
        }
      }
    },

    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.ers {
  position: relative;
  margin-top: 1rem;
  .revfd {
    position: absolute;
    top: 20%;
    left: 78%;
    font-size: 0.5rem;
    color: #19b5fe;
  }
}
</style>

